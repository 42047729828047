@import "../FuturaPT/stylesheet.css";
.headerWrap {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Futura PT";
  font-weight: bold;
  font-size: 30px;
}
.logoSpan {
  color: rgb(12, 12, 92);
}
.wrapPhone {
  display: flex;
  align-items: center;
}
.phoneA {
  font-family: "Futura PT";
  font-weight: bold;
  font-size: 30px;
  text-decoration: none;
  color: rgb(12, 12, 92);
}
.iFillPhone {
  color: rgb(12, 12, 92);
  margin-right: 20px;
}
.wrapFirstBlock {
  background: url("../img/qwer.jpg") no-repeat;
  background-size: cover;
  display: flex;

  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.wrapListQuiz {
  width: 600px;
  height: 400px;
  background: white;
  border-radius: 20px;
  box-shadow: 4px 4px 44px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.shadowBlock {
  width: 50%;
}
.fastCalc {
  font-family: "Futura PT";

  font-size: 45px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  line-height: 1.5;
  margin: 0;
}
.spanMkl {
  color: rgb(17, 17, 121);
}
.smalDescl {
  font-family: "Futura PT";
  color: black;
  font-size: 30px;
  margin: 0;
}
.spanSmall {
  color: rgb(17, 17, 121);
}
.wrapBlock {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapPblock {
  margin-top: 20px;
  width: 620px;

  height: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 4px 4px 44px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(17, 17, 121);
  font-family: "Futura PT";
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
  animation: pulse 1s infinite;
}
.wrapPblock:hover {
  animation: pulse 1s infinite; /* Анімація пульсації при наведенні */
}
.aCalculator {
  margin-right: 20px;
}
.wrapTwoButton {
  width: 660px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.wrapTimer {
  padding: 15px;
  width: calc(285px);
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  background: rgb(17, 17, 121);
}
.wrapTime {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timesert {
  font-family: "Futura PT";
  font-size: 30px;
  margin-left: 15px;
  color: white;
}
.fdp {
  font-family: "Futura PT";
  font-size: 20px;
  color: white;
}
.smallButtonQw {
  background: white;
  border-radius: 20px;
  box-shadow: 4px 4px 44px 0px rgba(0, 0, 0, 0.05);
  height: 40px;
  padding: 20px;
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.iOutlineClockCircle {
  color: white;
  font-size: 50px;
}
.smallButtonQw:hover {
  animation: pulse 1s infinite; /* Анімація пульсації при наведенні */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Збільшення розміру на 5% */
  }
  100% {
    transform: scale(1);
  }
}
.aBoxOpen {
  color: rgb(17, 17, 121);
  flex-shrink: 0;
  font-size: 39px;
  margin-right: 30px;
}
.pInSmallE {
  margin: 0;
  color: rgb(17, 17, 121);
  font-family: "Futura PT";
  font-size: 20px;
}
.wrapSecondBlock {
  width: calc(100% - 80px);
  padding: 40px;
  height: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoPlayer {
  width: auto;
  height: 100%;
}
.videoWrap {
  width: 650px;
  height: 700px;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.posterWrap {
  position: relative;
}
.buttonPlayVideo {
  position: absolute;
  top: calc(50% - 55px);
  cursor: pointer;
  left: calc(50% - 55px);
}
.sliderContainer {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.slide {
  width: 100%;
  height: auto;
  position: relative;
}

.slideImage {
  width: 100%;
  height: auto;
}

.description {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Напівпрозорий фон для тексту */
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.nextArrow,
.prevArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 5px;
  cursor: pointer;
  z-index: 2; /* Відображати поверх слайдів */
  background: rgb(17, 17, 121);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.nextArrow {
  right: -3%;
}

.prevArrow {
  left: -3%;
}
.wrapFooter {
  width: calc(100% - 40px);
  height: 200px;
  background: rgb(17, 17, 121);
  margin-top: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.linkNumberK {
  font-family: "Futura PT";
  font-size: 30px;
  text-decoration: none;
  color: white;
  margin-top: 30px;
}
.buttonPost {
  width: 250px;
  height: calc(50px - 20px);
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(17, 17, 121);
  font-family: "Futura PT";
  font-size: 30px;
  padding: 10px;
  transition: transform 0.3s ease;
}
.buttonPost:hover {
  animation: pulse 1s infinite; /* Анімація пульсації при наведенні */
}
.wrapQuiz {
  position: fixed; /* Фіксоване позиціонування для покриття всього екрану */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Чорний колір з прозорістю для затемнення */
  display: flex;
  justify-content: center; /* Вирівнювання по горизонталі по центру */
  align-items: center; /* Вирівнювання по вертикалі по центру */
  z-index: 1000; /* Високий індекс, щоб компонент завжди був зверху */
}
.iOutlineClose {
  position: absolute;
  top: 2%;
  font-size: 30px;
  cursor: pointer;
  right: 2%;
}
.allWrapP {
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.wrapSatndartInQuiz {
  width: auto;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.questionsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.progressBar {
  height: 20px;
  width: 700px;
  background-color: #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.progress {
  height: 100%;
  background-color: rgb(17, 17, 121);
  border-radius: 10px;
  transition: width 0.5s;
}

.optionButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
}

.inputField {
  display: block;
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid rgb(17, 17, 121);
  border-radius: 5px;
  margin-bottom: 25px;
  font-family: "Futura PT";
  color: rgb(17, 17, 121);
  font-size: 20px;
}
.inputField::placeholder {
  font-family: "Futura PT";
  color: rgb(17, 17, 121);
  font-size: 20px;
}
.inputFieldPop {
  display: block;
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid rgb(17, 17, 121);
  border-radius: 5px;
  margin-bottom: 25px;
  font-family: "Futura PT";
  color: rgb(17, 17, 121);
  font-size: 20px;
}
.inputFieldPop::placeholder {
  font-family: "Futura PT";
  color: rgb(17, 17, 121);
  font-size: 20px;
}
.submitButton {
  width: 100%;
  padding: 10px;
  background-color: rgb(17, 17, 121);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  font-family: "Futura PT";
}

.stepWrapP {
  font-family: "Futura PT";
  font-size: 30px;
}
.optionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}

.radioInput {
  position: absolute;
  opacity: 0; /* Приховуємо стандартну радіо-кнопку */
  cursor: pointer;
}

.radioInput:checked + .customRadio {
  background-color: #007bff; /* Синій колір для вибраного стану */
  border-color: #007bff;
}

.customRadio {
  width: 20px; /* Задайте ширину на свій розсуд */
  height: 20px; /* Задайте висоту на свій розсуд */
  border: 2px solid #ccc; /* Колір обвідки */
  border-radius: 50%; /* Кругла форма */
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.customRadio::after {
  content: "";
  width: 10px; /* Задайте ширину точку на свій розсуд */
  height: 10px; /* Задайте висоту точку на свій розсуд */
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: none; /* Приховано за замовчуванням */
}

.radioInput:checked + .customRadio::after {
  display: block; /* Показуємо, коли обрано */
}

.radioText {
  font-size: 25px;
  color: #333;
  font-family: "Futura PT";
}
.prevButton {
  width: 100px;
  height: 40px;
  background: rgb(17, 17, 121);
  border-radius: 5px;
  color: white;
  font-family: "Futura PT";
  font-size: 15px;
  cursor: pointer;
}
.nextButton {
  width: 300px;
  height: 40px;
  background: rgb(17, 17, 121);
  border-radius: 5px;
  color: white;
  font-family: "Futura PT";
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
}
.navigationButtons {
  margin-top: 20px;
}
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Темний напівпрозорий фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Високий z-index, щоб попап був зверху */
}

.modalContent {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: rgb(17, 17, 121);
}
@media only screen and (max-width: 479px) and (min-width: 300px) {
  .allWrapP {
    width: calc(100% - 20px);
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .wrapQuiz {
    position: fixed; /* Фіксоване позиціонування для покриття всього екрану */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Чорний колір з прозорістю для затемнення */
    display: flex;
    justify-content: center; /* Вирівнювання по горизонталі по центру */
    align-items: center; /* Вирівнювання по вертикалі по центру */
    z-index: 1000; /* Високий індекс, щоб компонент завжди був зверху */
  }
  .progressBar {
    height: 20px;
    width: 70%;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 1px;
  }
  .stepWrapP {
    font-family: "Futura PT";
    font-size: 20px;
  }
  .optionLabel {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
  }
  .radioText {
    font-size: 20px;
    color: #333;
    font-family: "Futura PT";
  }
  .navigationButtons {
    margin-top: 10px;
  }
  .nextButton {
    width: 200px;
    height: 40px;
    background: rgb(17, 17, 121);
    border-radius: 5px;
    color: white;
    font-family: "Futura PT";
    font-size: 15px;
    margin-left: 20px;
    cursor: pointer;
  }
  .wrapSatndartInQuiz {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .wrapTimer {
    padding: 15px;
    width: calc(285px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    background: rgb(17, 17, 121);
    margin-top: 10px;
  }
  .iOutlineClockCircle {
    color: white;
    font-size: 30px;
  }
  .timesert {
    font-family: "Futura PT";
    font-size: 30px;
    margin-left: 15px;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
  }
  .fdp {
    font-family: "Futura PT";
    font-size: 20px;
    color: white;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .wrapFirstBlock {
    background: url("../img/qwerSmall.png") no-repeat;
    background-size: cover;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-top: 100px;
  }
  .shadowBlock {
    width: 0%;
  }
  .wrapListQuiz {
    width: calc(90% - 60px);
    height: 340px;
    background: white;
    border-radius: 20px;
    box-shadow: 4px 4px 44px 0px rgba(0, 0, 0, 0.05);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .fastCalc {
    font-size: 25px;
  }
  .smalDescl {
    font-size: 22px;
  }
  .wrapPblock {
    width: calc(90% - 40px);
    font-size: 23px;
  }
  .wrapTwoButton {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .smallButtonQw {
    width: calc(90% - 40px);
    margin-bottom: 20px;
  }
  .headerWrap {
    font-size: 25px;
  }
  .phoneA {
    font-size: 25px;
  }
  .wrapSecondBlock {
    width: calc(100% - 20px);
    padding: 10px;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .videoWrap {
    width: 98%;
    height: auto;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .posterImage {
    width: 98%;
    height: auto;
  }
  .videoPlayer {
    width: 98%;
    height: auto;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) {
  .allWrapP {
    width: calc(100% - 20px);
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .wrapQuiz {
    position: fixed; /* Фіксоване позиціонування для покриття всього екрану */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Чорний колір з прозорістю для затемнення */
    display: flex;
    justify-content: center; /* Вирівнювання по горизонталі по центру */
    align-items: center; /* Вирівнювання по вертикалі по центру */
    z-index: 1000; /* Високий індекс, щоб компонент завжди був зверху */
  }
  .progressBar {
    height: 20px;
    width: 70%;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 1px;
  }
  .stepWrapP {
    font-family: "Futura PT";
    font-size: 20px;
  }
  .optionLabel {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
  }
  .radioText {
    font-size: 20px;
    color: #333;
    font-family: "Futura PT";
  }
  .navigationButtons {
    margin-top: 10px;
  }
  .nextButton {
    width: 200px;
    height: 40px;
    background: rgb(17, 17, 121);
    border-radius: 5px;
    color: white;
    font-family: "Futura PT";
    font-size: 15px;
    margin-left: 20px;
    cursor: pointer;
  }
  .wrapSatndartInQuiz {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .wrapTimer {
    padding: 15px;
    width: calc(285px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    background: rgb(17, 17, 121);
    margin-top: 10px;
  }
  .iOutlineClockCircle {
    color: white;
    font-size: 30px;
  }
  .timesert {
    font-family: "Futura PT";
    font-size: 30px;
    margin-left: 15px;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
  }
  .fdp {
    font-family: "Futura PT";
    font-size: 20px;
    color: white;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .wrapFirstBlock {
    background: url("../img/qwer.jpg") no-repeat;
    background-size: cover;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-top: 100px;
  }
  .shadowBlock {
    width: 0%;
  }
  .wrapListQuiz {
    width: calc(90% - 60px);
    height: 340px;
    background: white;
    border-radius: 20px;
    box-shadow: 4px 4px 44px 0px rgba(0, 0, 0, 0.05);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .fastCalc {
    font-size: 25px;
  }
  .smalDescl {
    font-size: 22px;
  }
  .wrapPblock {
    width: calc(90% - 40px);
    font-size: 23px;
  }
  .wrapTwoButton {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .smallButtonQw {
    width: calc(90% - 40px);
    margin-bottom: 20px;
    justify-content: center;
  }
  .headerWrap {
    font-size: 25px;
  }
  .phoneA {
    font-size: 25px;
  }
  .wrapSecondBlock {
    width: calc(100% - 20px);
    padding: 10px;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .videoWrap {
    width: 98%;
    height: auto;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .posterImage {
    width: 98%;
    height: auto;
  }
  .videoPlayer {
    width: 98%;
    height: auto;
  }
}

@media only screen and (max-width: 960px) and (min-width: 700px) {
  .allWrapP {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .wrapSatndartInQuiz {
    margin-left: 20px;
    margin-top: 20px;
  }
  .shadowBlock {
    width: 0%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 960px) {
  .allWrapP {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .wrapSatndartInQuiz {
    margin-left: 20px;
    margin-top: 20px;
  }
  .shadowBlock {
    width: 0%;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .shadowBlock {
    width: 0%;
  }
}
